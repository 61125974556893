//校验邮箱格式
function checkEmail(email) {
  return RegExp(/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/).test(email)
}
//校验手机格式
function checkMobile(mobile) {
  return RegExp(/^1[34578]\d{9}$/).test(mobile)
}
//校验姓名
function checkName(mobile) {
  return RegExp(/^[\u4E00-\u9FA5]{2,4}$/).test(mobile)
}
//校验身份证
function IdName(mobile) {
  return RegExp(/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/).test(mobile)
}
//密码格式
function passWord(mobile) {
  let str = mobile + ''
  if ((str.length > 6 || str.length == 6) && (str.length < 18 || str.length == 18)) {
    return true
  } else {
    return false
  }
}

module.exports = {
  checkEmail: checkEmail,
  checkMobile: checkMobile,
  checkName: checkName,
  IdName: IdName,
  passWord: passWord,
}
