export default {
	map: {
		placeholder: 'الرجاء إدخال عنوان البحث',
		btn: 'بحث ',
		btn2: 'تأكيد العنوان',
		toast1: 'تأكيد العنوان',
		toast2: 'لم يتم العثور على العنوان الصحيح',
		toast3: 'الموقع الجغرافي فشل الترميز',

	},
	address: {
		tips: 'جديلة',
		tipContent: "هل أنت متأكد من حذف هذا العنوان ؟",
		tipResult: 'حذف بنجاح',
	},
	editAddress: {
		partTit1: 'معلومات الموقع',
		lineTit1: 'منطقة',
		placeholder1: 'يرجى إدخال عنوان الاستلام',
		lineTit2: 'العنوان التفصيلي',
		partTit2: 'معلومات شخصية',
		lineTit3: 'اللقب',
		placeholder2: 'الرجاء إدخال اسم العائلة',
		lineTit4: 'الاسم',
		placeholder3: 'الرجاء إدخال اسم',
		lineTit5: 'رقم الهاتف',
		placeholder4: 'الرجاء إدخال رقم الهاتف',
		set: 'تعيين عنوان افتراضي',
		save: 'حفظ المعلومات',
		toast1: 'معلومات العنوان غير مكتملة',
		toast2: 'الاسم الكامل',
		toast3: 'يرجى ملء رقم الهاتف',
		toast4: 'المعلومات المحفوظة',
	},
	operateBtn: {
		cancelBtn: 'ألغى ',
		sureBtn: 'أكّد',
	},
	home: {
		hot_sale: 'السلع الساخنة',
		time_sale: 'التفضيلية هناك حدود زمنية ',
		all_discount: 'جميع التفضيلية',
		good_recomment: 'المنتجات الموصى بها',
		deliver: 'تسليم',
	},
	kind: {

	},
	account: {
		welcome_title1: 'مرحباً، سررت بلقائك',
		welcome_title2: 'أنت لا تسجل الدخول الآن',
		login: 'تسجيل الدخول',
		regist: 'تسجيل',
		order_dzf: 'تنتظر الدفع',
		order_dfh: 'في انتظار التسليم',
		// order_dsh: 'في انتظار الاستلام,في انتظار الاستلام
		order_dsh: 'في انتظار استلام البضائع',
		order_ywc: 'اكتمل',
		my_account: ',حسابي',
		my_info: 'معلومات شخصية',
		address: 'عنوان التسليم',
		feedback: 'تعليقات',
		coupon: 'التفضيلية القسيمة',
		set: 'إنشاء',
		language: 'اللغة',
		quit: 'الانسحاب',
		tips_title1: 'تم تأكيد العملية',
		tips_content1: 'هل أنت متأكد أنك تريد الخروج من تسجيل الدخول؟',
		zhuxiao: 'تسجيل الخروج',
		tips_zhuxiao: 'هل أنت متأكد أنك تريد تسجيل الخروج?',
		full: 'كامل',
		reduce: 'ناقص',
		ShoppingCart: 'عربة التسوق',
		order: 'أمر',
		logOff: 'إلغاء الحساب',
	},
	car: {
		extra_reduce: 'تقليل إضافي',
		delete: 'الحد',
		add_Wish: 'أضف إلى قائمة الأمنيات',
		lookFor: 'يبدو أنه يبحث',
		total: 'مجموع',
		choose_coupon: 'يرجى اختيار قسيمة العرض',
		save: 'وفورات',
		mjq: 'كوبونات خصم كاملة',
		use: 'استخدام',
		settle: 'بالحساب.',
		subtotal: 'المجموع الفرعي',
		tips_title1: 'يرجى إضافة البضائع للتسوية',
		tips_content1: 'تأكيد لحذف؟',
		empty: 'عربة التسوق فارغة',
	},
	address: {
		addBtn: "إضافة عنوان جديد",
	},
	coupon: {
		full: 'ممتلئة',
		reduce: 'خفض',
		list_all: 'كل',
		list_dsy: 'في انتظار الاستخدام',
		list_ysy: 'بالفعل الاستخدام',
		list_ygq: 'منتهية الصلاحية',
		empty: 'البيانات فارغة',
	},
	feedback: {
		explain: 'تعليمات النص',
		placeholder1: 'يرجى إدخال التغذية المرتدة',
		image: 'الصورة',
		btn: 'تقديم',
		star: 'ستار',
		placeholder2: 'يرجى إدخال تقييم المحتوى',
		toast_title: 'يرجى ملء في تقييم المحتوى',
		upload_tip: 'يتم تحميل الصورة ، يرجى الانتظار'
	},
	goodDetail: {
		advantage1: 'العديد من التعليقات الجيدة',
		advantage2: 'الدفع عند التسليم',
		advantage3: 'الصفقة آمنة',
		evaluation: 'التقييم العام',
		comment: 'تعليقات',
		addCar: 'أضف إلى عربة التسوق',
		wishlist: 'قائمة أمنيات',
		showDefault: 'افتراضي',
		manage_wishlist: 'إدارة قائمة أمنياتي',
		save: 'حفظ',
		count: 'عدد',
		product_amount: 'قيمة البضائع',
		continue_shop: 'مواصلة التسوق',
		menu1: 'لمحة عامة',
		menu2: 'توضيح',
		yunshuxiangqing: 'تفاصيل الشحن',
		tedian: 'خصائص السلع',
	},
	orderDetail: {
		orderId: 'رقم الطلب',
		delivery_address: 'عنوان التسليم',
		putAway: 'قابلة للطي',
		open: 'افتح',
		product_list: 'قائمة السلع',
		tips: 'بمجرد التعبئة، لا يمكن إلغاء سلعة واحدة في الطلب',
	},
	orders: {
		placeholder1: 'يرجى إدخال معلومات الطلب',
		label1: 'الفرز',
		orderStatus1: 'غير معروف',
		orderStatus2: 'تنتظر الدفع',
		orderStatus3: 'في انتظار التسليم',
		orderStatus4: 'في انتظار الاستلام',
		orderStatus5: 'اكتمل',
	},
	profile: {
		profile_info: 'بيانات شخصية',
		email: 'صندوق البريد',
		first_name: 'اسم',
		last_name: 'اللقب',
		phone_number: 'رقم الهاتف',
		tips: 'يمكن استخدام هذا لتسجيل الدخول على جميع التطبيقات.',
		add: "زيادة",
		birthday: 'عيد ميلاد',
		gender: 'نوع الجنس',
		male: 'الرجال',
		female: 'النساء',
		nationality: 'الجنسية',
		selectNationality: 'اختر الجنسية',
		add_phoneNumber: 'أضف رقم الهاتف',
		tips1: 'يرجى إدخال رقم الهاتف المحمول الجديد',
		tips2: 'رقم هاتف جديد',
	},
	settlement: {
		address: 'العنوان',
		door: 'سواء لوضعها على الباب',
		addAddress: 'إضافة عنوان الاستلام'
	},
	shopList: {
		num: 'رقم',
		sort: 'الفرز',
	},
	wishList: {
		edit_name: 'اسم التحرير',
		placeholder1: 'الرجاء إدخال اسم',
		tips1: 'تأكد من حذف قائمة الأمنيات',
		tips2: 'تأكد من إفراغ قائمة الأمنيات',
	},
	login: {
		desc: 'هارا دعنا نَبْدأُ.',
		sendTxt: 'تم إرسال رمز التحقق إلى',
		login: ' تسجيل الدخول ',
		regist: ' تسجيل ',
		placeholder1: 'الرجاء إدخال صندوق البريد أو رقم الهاتف',
		placeholder2: 'يرجى إدخال كلمة المرور الدخول',
		placeholder3: 'يرجى إدخال صندوق البريد',
		placeholder4: 'يرجى إدخال كلمة المرور الدخول',
		placeholder5: 'يرجى ملء كلمة المرور',
		placeholder6: 'يرجى ملء رمز التحقق',
		text1: ' مواصلة ',
		text2: ' تسجيل الدخول ',
		title1: 'اختر رقم المنطقة',
		xieyi1: 'اقرأ وتوافق',
		xieyi2: 'بروتوكول المستخدم',
		xieyi3: 'و .',
		xieyi4: 'سياسة الخصوصية',
		tipxieyi: 'يرجى قراءة والموافقة على اتفاق المستخدم وسياسة الخصوصية',
	},
	pageBottom: {
		support_help: 'نحن دائما هنا للمساعدة',
		custom: 'مركز خدمة العملاء',
		email: 'دعم البريد',
		shop: "التسوق في أي وقت وفي أي مكان",

		Careers: 'التطور الوظيفي',
		Warranty_Policy: "سياسة الضمان",
		Sell_with_us: 'انضم إلينا',
		Terms_of_Use: 'شروط الاستخدام',
		Privacy_Policy: 'سياسة الخصوصية',
		Consumer_Rights: 'حقوق المستهلك',
	},
	search: {
		placeholder1: 'ما الذي تبحث عنه؟？',
		open: 'فتح .',
	},
	tab: {
		menu1: 'الصفحة الرئيسية',
		menu2: 'تصنيفات',
		menu3: 'الحسابات',
		menu4: 'عربة التسوق'
	},
	homeTap: 'الصفحة الرئيسية',
	classification: 'تصنيفات',

	PersonalInformation: 'معلومات شخصية',

	SelectDate: 'اختيار التاريخ',
	Tips: "نصائح دافئة",
	Whatareyoulookingfor: "ما الذي تبحث عنه ؟",
	scoreL: 'سجل',
	collect: 'جمع',
	ProductDetails: 'تفاصيل المنتج',
	Delivery: 'أرسل',
	pages: {
		home: 'الصفحة الرئيسية',
		kind: 'تصنيفات',
		account: 'الحسابات',
		car: 'عربة التسوق',
		address: 'عنوان',
		editAdd: 'تحرير العنوان',
		feedback: 'ملاحظات',
		coupon: 'كوبونات',
		shopList: 'قائمة السلع',
		goodDetail: 'تفاصيل المنتج',
		profile: 'معلومات شخصية',

		orders: 'أمر',
		orderDetail: "تفاصيل النظام",
		map: "اختيار العنوان",
		wishList: 'قائمة الرغبات',
		settlement: 'تسوية'
	}
}