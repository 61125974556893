export default {
	operateBtn: {
		cancelBtn: '取消',
		sureBtn: '确认'
	},
	home: {
		hot_sale: '热销商品',
		time_sale: '限时秒杀',
		all_discount: '全部优惠',
		good_recomment: '好物推荐',
	},
	kind: {

	},
	account: {
		welcome_title1: '你好，很高兴认识你',
		welcome_title2: '你当前未登录哟~',
		login: '登录',
		regist: '注册',
		order_dzf: '待支付',
		order_dfh: '待发货',
		order_dsh: '待收货',
		order_ywc: '已完成',
		my_account: '我的账户',
		my_info: '个人信息',
		address: '收货地址',
		feedback: '意见反馈',
		coupon: '优惠券',
		set: '设置',
		language: '语言',
		quit: '退出',
		tips_title1: '操作确认',
		tips_content1: '确定要退出登录吗',
		ShoppingCart: '购物车',
		order: '订单',
		logOff: '注销账户',


	},
	car: {
		extra_reduce: '额外减少',
		delete: '减少',
		add_Wish: '添加到心愿清单',
		lookFor: '似乎在找',
		total: '合计',
		choose_coupon: '请选择优惠券',
		save: '节省',
		mjq: '满减券',
		use: '使用',
		settle: '结算',
		subtotal: '小计',
		tips_title1: '请添加商品进行结算',
		tips_content1: '确认要删除吗',
		empty: '购物车为空',
	},
	address: {
		addBtn: "添加新地址"
	},
	coupon: {
		full: '满',
		reduce: '减',
		list_all: '全部',
		list_dsy: '待使用',
		list_ysy: '已使用',
		list_ygq: '已过期',
		empty: '数据为空',
	},
	feedback: {
		explain: '文字说明',
		placeholder1: '请输入反馈内容',
		image: '图片',
		btn: '提 交'
	},
	goodDetail: {
		advantage1: '众多好评',
		advantage2: '货到付款',
		advantage3: '交易安全',
		evaluation: '总体评价',
		comment: '评论',
		addCar: '添加到购物车',
		wishlist: '心愿清单',
		showDefault: '默',
		manage_wishlist: '管理我的心愿清单',
		save: '保存',
		count: '数量',
		product_amount: '商品金额',
		continue_shop: '继续购物',
		menu1: '概述',
		menu2: '说明',
	},
	orderDetail: {
		orderId: '订单ID',
		delivery_address: '送货地址',
		putAway: '收起',
		open: '展开',
		product_list: '商品列表',
		tips: '一旦打包后，单件商品无法取消订单',
	},
	orders: {
		placeholder1: '请输入订单信息',
		label1: '筛选',
		orderStatus1: '未知',
		orderStatus2: '待支付',
		orderStatus3: '待发货',
		orderStatus4: '待收货',
		orderStatus5: '已完成',
	},
	profile: {
		profile_info: '个人资料',
		email: '邮箱',
		first_name: '名',
		last_name: '姓',
		phone_number: '手机号码',
		tips: '这可用于登录所有中午应用程序。',
		add: "添加",
		birthday: '生日',
		gender: '性别',
		male: '男性',
		female: '女性',
		nationality: '国籍',
		selectNationality: '选择国籍',
		add_phoneNumber: '添加手机号码',
		tips1: '请输入新的手机号',
		tips2: '新的手机号',
		add_phone_button: '确认修改'
	},
	settlement: {
		address: '地址',
		door: '是否放在门口',
	},
	shopList: {
		num: '个',
		sort: '排序',
	},
	wishList: {
		edit_name: '编辑名称',
		placeholder1: '请输入名称',
		tips1: '确定删除该心愿清单',
		tips2: '确定清空该心愿清单',
	},
	login: {
		desc: '哈拉！我们开始吧',
		sendTxt: '验证码已经发送至',
		login: ' 登 录 ',
		regist: ' 注 册 ',
		placeholder1: '请输入邮箱或者手机号',
		placeholder2: '请输入登录密码',
		placeholder3: '请输入邮箱',
		placeholder4: '请输入登录密码',
		placeholder5: '请填写密码',
		placeholder6: '请填写验证码',
		text1: ' 继 续 ',
		text2: ' 登 录 ',
		title1: '选择区号'
	},
	pageBottom: {
		support_help: '我们始终在这里提供帮助',
		custom: '客户服务中心',
		email: '邮件支持',
		shop: "随时随地购物",

		Careers: '职业发展',
		Warranty_Policy: "保修政策",
		Sell_with_us: '加入我们',
		Terms_of_Use: '使用条款',
		Privacy_Policy: '隐私政策',
		Consumer_Rights: '消费者权益',
	},
	search: {
		placeholder1: '你在找什么？'
	},
	homeTap: '首页',
	classification: '分类',

	PersonalInformation: '个人信息',

	SelectDate: '选择日期',
	Tips: "温馨提示",
	Whatareyoulookingfor: "你在找什么？",
	scoreL: '评分',
	collect: '收藏',
	ProductDetails: '商品详情',
	Delivery: '送到',
	map: {
		placeholder: '请输入搜索地址',
		btn: '搜 索',
		btn2: '确认地址',
		toast1: '请选择一个收货地址',
		toast2: '未找到合适地址',
		toast3: '地理位置编码失败',
	},
	pages: {
		home: '首页',
		kind: '分类',
		account: '账户',
		car: '购物车',
		address: '地址',
		editAdd: '编辑地址',
		feedback: '意见反馈',
		coupon: '优惠券',
		shopList: '商品列表',
		goodDetail: '商品详情',
		settlement: '结算',
		profile: '个人信息',



		orders: '订单',
		orderDetail: "订单详情",
		map: "选择地址",
		wishList: '心愿清单',
		settlement: '结算'
	},
	editAddress: {
		partTit1: '位置信息',
		lineTit1: '地区',
		placeholder1: '请输入收货地址',
		lineTit2: '详细地址',
		partTit2: '个人信息',
		lineTit3: '姓',
		placeholder2: '请输入姓氏',
		lineTit4: '名',
		placeholder3: '请输入名称',
		lineTit5: '联系电话',
		placeholder4: '请输入电话号码',
		set: '设为默认地址',
		save: '保存信息',
		toast1: '地址信息不完整',
		toast2: '姓名填写不完整',
		toast3: '请填写电话号码',
		toast4: '信息已保存',
	},
}