import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'nav',
    component: (resolve) => require(['@/components/nav'], resolve),
    children: [
      { path: '/', name: 'index', meta: { title: '首页' }, component: (resolve) => require(['@/views/index'], resolve) }, //首页
      { path: '/login', name: 'login', meta: { title: '登录' }, component: (resolve) => require(['@/views/login'], resolve) }, //登录
      { path: '/register', name: 'register', meta: { title: '注册' }, component: (resolve) => require(['@/views/register'], resolve) }, //注册
      { path: '/retrieve', name: 'retrieve', meta: { title: '找回密码' }, component: (resolve) => require(['@/views/retrieve'], resolve) }, //找回密码
      { path: '/about', name: 'about', meta: { title: '关于我们' }, component: (resolve) => require(['@/views/about'], resolve) }, //找回密码
      { path: '/goodDetails', name: 'goodDetails', meta: { title: '详情' }, component: (resolve) => require(['@/views/goodDetails'], resolve) }, //找回密码
      { path: '/orderDetails', name: 'orderDetails', meta: { title: '订单详情' }, component: (resolve) => require(['@/views/orderDetails'], resolve) }, //找回密码
      { path: '/orderCon', name: 'orderCon', meta: { title: '订单确定' }, component: (resolve) => require(['@/views/orderCon'], resolve) }, //找回密码

      { path: '/personal', name: 'personal', meta: { title: '商品列表' }, component: (resolve) => require(['@/views/user/personal'], resolve) }, //个人中心
      { path: '/personal2', name: 'personal2', meta: { title: '搜索结果' }, component: (resolve) => require(['@/views/user/personal2'], resolve) }, //个人中心
      { path: '/wishList', name: 'wishList', meta: { title: '心愿清单' }, component: (resolve) => require(['@/views/user/wishList'], resolve) }, //个人中心
      {
        path: '/userComponents',
        name: 'userComponents',
        meta: { title: '商品' },
        component: (resolve) => require(['@/views/user/index'], resolve),
        children: [

          { path: '/agreement', name: 'agreement', meta: { title: '心愿清单' }, component: (resolve) => require(['@/views/agreement'], resolve) }, //个人中心
          {
            path: '/information',
            name: 'information',
            meta: { title: '我的收藏' },
            component: (resolve) => require(['@/views/user/information'], resolve),
          }, //个人中心
          {
            path: '/curriculum',
            name: 'curriculum',
            meta: { title: '订单' },
            component: (resolve) => require(['@/views/user/curriculum'], resolve),
          }, //个人中心
          {
            path: '/examination',
            name: 'examination',
            meta: { title: '购物车' },
            component: (resolve) => require(['@/views/user/examination'], resolve),
          }, //个人中心
          {
            path: '/settlement',
            name: 'settlement',
            meta: { title: '确认订单' },
            component: (resolve) => require(['@/views/user/settlement'], resolve),
          }, //个人中心
          {
            path: '/preferential',
            name: 'preferential',
            meta: { title: '优惠卷' },
            component: (resolve) => require(['@/views/user/preferential'], resolve),
          }, //个人中心
          { path: '/Address', name: 'Address', meta: { title: '收货地址' }, component: (resolve) => require(['@/views/user/Address'], resolve) },
          { path: '/editAdd', name: 'editAdd', meta: { title: '收货地址' }, component: (resolve) => require(['@/views/user/editAdd'], resolve) },

        ],
      }, //用户个人中心
    ],
  },
]
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}
// history hash
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    window.document.title = to.meta.title
  } else {
    next()
  }

  console.log(to)
  // 判断是否登录
  if (
    to.path == '/' ||
    to.path == '/Journalism' ||
    to.path == '/login' ||
    to.path == '/retrieve' ||
    to.path == '/register' ||
    to.path == '/course' ||
    to.path == '/courseList' ||
    to.path == '/courseListSearch' ||
    to.path == '/JournalismDetails'
  ) {
    next()
  } else if (window.localStorage.getItem('jx-token')) {
    next()
  } else {
    next()
    // next({
    //   name: 'login',
    // })
    // gtMessage.info('请先登录')
  }
})

export default router
